const realmMenu = [
    'mainmenu.mainmenu',
    'mainmenu.dashboard',
    'mainmenu.subjects',
    'mainmenu.subjects.primary',
    'mainmenu.subjects.junior',
    'mainmenu.subjects.senior',
    'mainmenu.users',
    'mainmenu.users.guardians',
    'mainmenu.users.students',
    'mainmenu.questions',
    'mainmenu.questions.questionView',
    'mainmenu.questions.questionUpload',
    'mainmenu.questions.questionReview',
    'mainmenu.questions.normalisation',
    'mainmenu.admin',
    'mainmenu.adminQuestion.questionBank',
    'mainmenu.adminQuestion.questionPool',
    'actions.actions',
    'actions.payout',
    // 'actions.documentation',
    // 'actions.documentation.toc',
    // 'actions.documentation.page',
    'verification.verification',
    'verification.verificationBank',
    'verification.verificationDocument',
    'verification.verificationAttribute',
    'verification.verificationInterest',
    'operations.operations',
    'operations.officers',
    'operations.officers.officersManage',
    'operations.themes',
    'operations.themes.themesView',
    'operations.settlement',
    'operations.settlement.settlementView',
    'account.account',
    'account.documentUpload',
    'account.wallet',
    'account.cashout',
    'account.fees'
]

export default realmMenu