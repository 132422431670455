<template>
  <div id="splash">
      <img src="@/assets/animation_loader.gif" width="100" alt="loader">
  </div>
</template>

<script>
export default {
    
}
</script>

<style>
    #splash{
        top: 0;
        left: 0;
        position: absolute;
        background: white;
        width: 100vw;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99999999999;
    }
</style>