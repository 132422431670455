const reviewerMenu = [
    'mainmenu.mainmenu',
    'mainmenu.dashboard',
    'mainmenu.questions',
    'mainmenu.questions.questionReview',
    'account.account',
    'account.documentUpload',
    'account.wallet',
    'account.cashout',
    'account.fees'
]

export default reviewerMenu;