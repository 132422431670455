const jQ = window.jQuery

Window.openVueBsModal = (id) => {
  jQ('#' + id).modal()
}
Window.closeVueBsModal = (id) => {
  jQ('#' + id).modal('hide')
}
Window.trigger = (event, selector) => {
    jQ(selector).trigger(event)
}

jQ(document).ready(function () {
jQ('.has-child ul a').on('click', function (e) {

    if(screen.width <1150){  
      jQ(e.target).parent().parent().attr('style', 'display: none !important')
      document.querySelector('.sidebar-toggle').click()
    }
    
  })
  jQ('.sidebar_nav>li:not(.has-child)>a').on('click', function () {
    if(screen.width <1150){  
      document.querySelector('.sidebar-toggle').click()
        
   
    }
  })
});
