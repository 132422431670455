<template>
    <header class="header-top">
        <nav class="navbar navbar-light">
            <div class="navbar-left">
                <a href="" class="sidebar-toggle">
                    <img class="svg" src="@/assets/img/svg/bars.svg" alt="img"></a>
                <router-link class="navbar-brand" to="/"><img class="svg dark" src="@/assets/img/custom/greater-favour.png" alt=""><img class="light" src="@/assets/img/Logo_white.png" alt=""></router-link>
            
            </div>
            <!-- ends: navbar-left -->
            <div class="navbar-right">
                <ul class="navbar-right__menu">
                    <li class="nav-search d-none">
                        <a href="#" class="search-toggle">
                            <i class="la la-search"></i>
                            <i class="la la-times"></i>
                        </a>
                        <form action="/" class="search-form-topMenu">
                            <span class="search-icon" data-feather="search"></span>
                            <input class="form-control mr-sm-2 box-shadow-none" type="search" placeholder="Search..." aria-label="Search">
                        </form>
                    </li>
                  
                    <!-- ends: nav-message -->
                    <li class="nav-notification">
                        <div class="dropdown-custom">
                            <a href="javascript:;" class="nav-item-toggle">
                                <!-- <span data-feather="bell"></span></a> -->
                                <span class="fa fa-bell"></span></a>
                            <div class="dropdown-wrapper">
                                <!-- <h2 class="dropdown-wrapper__title">Notifications <span class="badge-circle badge-warning ml-1">4</span></h2> -->
                                <h2 class="dropdown-wrapper__title">No Notification</h2>

                            </div>
                        </div>
                    </li>
                    <li class="nav-author">
                        <div class="dropdown-custom">
                            <a href="javascript:;" class="nav-item-toggle">
                                <span v-if="user" class="rounded-circle s-30" :style="{backgroundImage: `url(${getAvatar(user)})`}"> </span>
                            </a>
                            <div class="dropdown-wrapper">
                                <div class="nav-author__info">
                                    <div class="author-img">
                                       <span v-if="user" class="rounded-circle bg-pos s-30" :style="{backgroundImage: `url(${getAvatar(user)})`}"> </span>
                                    </div>
                                    <div v-if="user">
                                        <h6>{{user.lname}} {{user.fname}}</h6>
                                        <span>{{roleMap[user.role]}}</span>
                                    </div>
                                </div>
                                <div class="nav-author__options">
                                    <ul>
                                        <li class="attr px-3">
                                            <p style="font-size: 12.5px;">Attributes: </p>
                                            <ul class="d-flex" style="margin-top:-15px;">
                                                <li
                                                    v-for="item in allAttributes"
                                                >
                                                <strong class="mx-1">{{item}}</strong>
                                                </li>
                                            </ul>
                                        </li>
                                        <li>
                                            <router-link :to="{name: 'user-profile'}">
                                                <span data-feather="user"></span> Profile</router-link>
                                        </li>
                                    </ul>
                                    <a @click="logout()" class="nav-author__signout logout-link">
                                        <span data-feather="log-out"></span> Sign Out
                                    </a>
                                </div>
                            </div>
                            <!-- ends: .dropdown-wrapper -->
                        </div>
                    </li>
                    <!-- ends: .nav-author -->
                </ul>
                <!-- ends: .navbar-right__menu -->
            </div>
            <!-- ends: .navbar-right -->
        </nav>
    </header>
</template>

<script>
import { roleMap } from "@/utilities/constant";
import { mapState } from 'vuex';
export default {
    data(){
        return{
            roleMap
        }
    },
    computed:{
        ...mapState(['user']),
        allAttributes() {
            let attributes = this.user.idiosyncrasy

            const userAttributes = []
            for (const key in attributes) {
                if (attributes[key] == true) {
                    userAttributes.push(key)
                }
            }
            return userAttributes
        }
    },
    methods: {
        logout() {
            this.$store.commit('CLEAR_USER_DATA')
            setTimeout(()=>{
                this.$router.push({name: 'login', action: 'logout'})
            },1000)
        },
    },
}
</script>

<style>
.logout-link {
    cursor: pointer;
}
.s-30{
    width: 30px !important;
    height: 30px;
    display: inline-block;
    background-position: center;
    background-size: cover;
}
.attr strong{
  display: inline-block;
  padding: 3px 5px;
  border: 1px solid #CCC;
  margin-top: 3px;
  margin-right: 3px;
  border-radius: 3px;
}
</style>