<template>
  <div>
    <online-status />
    <Header></Header>
    <transition name="slide-fade">
      <div v-if="ctoast.message"
        class="signUp-topbar d-flex align-items-center justify-content-md-end justify-content-center mt-md-0 mb-md-0 mt-20 mb-1" style="position: fixed; top: 1em; right: 1em; z-index: 99999"
      >
        <div class="mb-0 mt-3">
        <div class="alert d-flex align-items-center" role="alert" :class="`alert-${ctoast.type}`">
            {{ctoast.message}}
            <span class="fa fa-times ml-3 mr-0" @click="ctoast.message = ''"></span>
        </div> 
        </div>
      </div>
    </transition>
    <div class="main-content">
      <SideNav :primary="primary" :junior="junior" :senior="senior" :themes="themes" :fees="fees" />
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>
      <Footer></Footer>
       <LoaderOverlay :active="asyncLoader" :fullpage="true" bgColor="#2a2a2a55" v-if="!splash" zIndex="99999"></LoaderOverlay>
       <splash-screen v-if="splash" />
        <!-- <LoaderOverlay :active="loader.active" :fullpage="loader.fullpage" bgColor="#2a2a2a55" zIndex="99999"></LoaderOverlay> -->
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header";
import SideNav from "@/components/SideNav";
import OnlineStatus from "@/components/OnlineStatus";
import Footer from "@/components/Footer";
import { authComputed } from "../vuex/helpers.js";
import { apiClient } from "@/plugins/restclient.js";
import LoaderOverlay from "@/components/LoaderOverlay";
import SplashScreen from "@/components/SplashScreen";
import { mapState } from 'vuex';
export default {
  components: {
    Header,
    SideNav,
    Footer,
    OnlineStatus,
    LoaderOverlay,
    SplashScreen
  },
  data () {
    return {
      classes: [],
      successRefresh: 0,
      failedRefresh: 0,
      refreshHandler: '',
      loader: {
          fullpage: true,
          active: false
      },
      subjects: [],
      topics: [],
      primary: [],
      junior: [],
      senior: [],
      primarySub: [],
      juniorSub: [],
      seniorSub: [],
      themes: [],
      fees: [],
      splash:false,
      session: {
        start: 0,
        end: 0,
      },
      sessionQueue: []
    }
  },
  watch:{
      ctoast(toast){
        if(toast.message == '' || toast.timeout == 'freeze'){
          return
        }
        setTimeout(()=>{
          this.$store.commit('ctoast', {message:'', type: ''})
        },toast.timeout)
      },
      sessionQueue(){
        // localStorage.setItem('sessionQueue', this.sessionQueue)
        // if(this.sessionQueue.length){
        //   this.retrySessionSend()
        // }
      },
  },
  computed: {
    ...mapState(['asyncLoader', 'ctoast']),
    ...authComputed,
  },
  beforeDestroy() {
    clearInterval(this.refreshHandler);
  },
  created() {
     this.$store.dispatch('tokenRefresh', this.envVariables.VUE_APP_TOKEN_REFRESH_INTERVAL)
     this.startProctor()
     this.interaction()
     this.fetchUserSubjectInterest()
    //  console.log(this.classMap, 'classMap')
  },
  beforeMount(){
    if (localStorage.getItem("verification")) {
      this.$router.push({ path: "/login" });
    } else if (this.loggedIn == false){
      this.$router.push({ path: "/login" });
    }
  },
  mounted() {
    require("../assets/js/main");
    require("../../public/assets/custom/main.js");
    if (this.loggedIn == true){
      this.getClasses();
    }
  },
  methods:{
    fetchUserSubjectInterest(){
      apiClient.get('/v1/engine/settings/interest/fetch').then(res => {
        this.$store.commit("setUserInterest", res.data.data)
      }).catch(() => {

      })
    },
    getClasses() {
      this.splash = true
      this.$store.commit('loader', true)
      var ts = Math.round(+new Date() / 1000);

      const headers = {
        'X-Ulid': `${this.$store.state.user.ulid}`,
        "X-Timestamp": ts,
      };

      apiClient.get('/v1/engine/core/environment', {
        headers: headers,
          }).then(res => {
            
        this.$store.commit('loader', false)
        this.splash = false
            var response = res.data.info;
            var classes = response.classes;
            var subjects = response.subjects;
            var topics = response.topics;
            var themes = response.themes;
            var fees = response.fees;
            var banks = response.banks.data
            var stat = response.stat
            this.$store.commit('setStat', stat)

            this.$store.commit('BANK_LISTING', banks)
            this.classes = Object.keys(classes).map(i => classes[i]);
            this.subjects = Object.keys(subjects).map(i => subjects[i]);

            this.topics = topics && Object.keys(topics).map(i => topics[i]) 
            this.themes = Object.keys(themes).map(i => themes[i]);

            this.fees = Object.keys(fees).map(i => fees[i]);

            this.$store.commit('SET_THEMES', this.themes)

            var primary =  this.classes.filter(function(hero) {
              return hero.category == "PRIMARY";
            });
            
            this.primary = primary;
           

            var junior =  this.classes.filter(function(hero) {
              return hero.category == "JUNIOR";
            });
            
            this.junior = junior;

            var senior =  this.classes.filter(function(hero) {
              return hero.category == "SENIOR";
            });
            
            this.senior = senior;

            var primarySub =  this.subjects.filter(function(hero) {
              return hero.category_class == "PRIMARY";
            });
            
            this.primarySub = primarySub;

            var juniorSub =  this.subjects.filter(function(hero) {
              return hero.category_class == "JUNIOR";
            });
            
            this.juniorSub = juniorSub;

            var seniorSub =  this.subjects.filter(function(hero) {
              return hero.category_class == "SENIOR";
            });
            
            this.seniorSub = seniorSub;
             let filteredClasses = this.classes;
             let filteredSubjects = this.subjects;
             let filteredTopics = this.topics;

            this.$store.commit('TOTAL_CLASSES', filteredClasses );
            this.$store.commit('setClassMap', classes );
            this.$store.commit('TOTAL_SUBJECTS', filteredSubjects );
            this.$store.commit('TOTAL_TOPICS', filteredTopics );
            this.$store.commit('primary', this.primary)
            this.$store.commit('junior', this.junior)
            this.$store.commit('senior', this.senior)
            this.$store.commit('SET_FEES', this.fees)
            this.$store.commit('primarySub', this.primarySub)
            this.$store.commit('juniorSub', this.juniorSub)
            this.$store.commit('seniorSub', this.seniorSub)
        }).catch(() => {
          this.splash = false
          this.$store.commit('loader', false)
          // console.log(err.response)
          // if (err.response.data.message == "access denied" && err.response.data.tags.includes("access denied")) {
          //   this.logout();
          // }
        });
    },
    logout() {
      this.$store.dispatch("logout");
    },
    getTimestamp() {
      return Math.round(+new Date() / 1000)
    },
    startProctor() {
      setInterval(()=>{
        this.sendSession()
      },60000)
    },
    interaction(){
       window.onmousemove = () => {
        this.session.start = this.session.start || this.getTimestamp();
        this.session.end = this.getTimestamp()
      };
      window.onkeypress = () => {
        this.session.start = this.session.start || this.getTimestamp();
        this.session.end = this.getTimestamp()
      };
    },
    sendSession(){
      if(!this.session.start) return
      apiClient.patch('/v1/engine/core/relay/activity', this.session,{
        headers:{
          'X-Timestamp': this.getTimestamp()
        }
      })
      // console.log(this.session.end - this.session.start, 'elapsed time')
      this.session.start = 0
      this.session.end = 0
    }
  },
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
