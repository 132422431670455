<template>
  <vue-online-offline>
    <div slot="offline">
        <div class="offline-wrap">
            <div class="offline">
                <div class="alert alert-warning alert-dismissible fade show" role="alert">
                <p>You seem to be Offline. Please connect to the internet and try again</p>
                <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
            </div>
        </div>
    </div>
  </vue-online-offline>
</template>

<script>
import VueOnlineOffline from 'vue-online-offline'

export default {
  components: { VueOnlineOffline },
 
}
</script>
<style scoped>
.offline{
    position: fixed;
    z-index: 99999;
    top: 90px;
    right: 10px;
    font-size: 11px;
}
.offline-wrap{
    display: flex;
    justify-content: flex-end;
}
</style>